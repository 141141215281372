.btn-block {
  width: 100%;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.nav-link {
  color: #0d6efd;
}
 
body {
  position: relative;
  color: #333;
  height: 100vh;
  font-family: sans-serif;
}

.available-services-container .btn {
  margin: 0.5rem;
}

.available-services-container {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  margin: 1rem auto;
  padding: 1rem;
}

textarea {
  border-color: #dee2e6;
}

.operations-btn-container {
  margin: 1rem auto;
}

.operations-btn-container .btn {
  margin: 1rem 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100vw;
  height: 100vh;
  background-color: rgba(100, 100, 100, 0.2)
}

.overflow {
  overflow: hidden;
}

.spinner {
  position: absolute;
  width: 10vw;
  height: 10vw;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
}